import React, { useState , useEffect } from 'react';
import { updateTalentAction , createTalentAction , deleteTalentAction, getTalentsAction } from "../../../actions/talentsActions";
import { API_URL } from '../../../actions';

import TextEditor from '../../partials/TextEditor';
import edit from '../../../assets/images/icons/edit.svg';
import remove from '../../../assets/images/icons/remove.svg';
import close from '../../../assets/images/icons/close.svg';
import save from '../../../assets/images/icons/save.svg';

export default function Talent({ talentValue , setRemoveAction , index , theme , lang , dispatch , openSelectPicture , openSelectTalentVideo , context , companyId }) {

  const [ talent , setTalent ] = useState({});
  const [ isEdit , setIsEdit ] = useState(false);
  const [ text , setText ] = useState("")

  useEffect(() => {
    setTalent({...talent ,...talentValue} );
    if(talentValue?.text) {
      setText(talentValue.text);
    }
    // eslint-disable-next-line
  },[talentValue])

  useEffect(() => {
    if (!talentValue?._id) {
      setIsEdit(true)
    }
    // eslint-disable-next-line
  }, [talentValue]);

  function renderTalentImg() {
    var imgFound;
    if(talent && talent.picture && context.medias.mediasList) {
      imgFound = context.medias.mediasList.find(item => item._id === talent.picture )
      if (imgFound) {
        return <img src={API_URL + '/' + imgFound.path} alt="talent" className={isEdit ? "img-small" : "circle" }/>
      } else if (talent.picture.path) {
        return <img src={API_URL + '/' + talent.picture.path} alt="talent" className={isEdit ? "img-small" : "circle" } />
      }
    }
  }

  function renderTalentVideo() {
    var videoFound;
    if(talent && talent.video && context.medias.mediasList) {
      videoFound = context.medias.mediasList.find(item => item._id === talent.video )
      if (videoFound) {        
        return (
          <video controls="controls" className="img-large">
            <source src={API_URL +'/'+ videoFound.path}  />
          </video>
        )
      } else if (talent.video.path) {
        return (
          <video controls="controls">
            <source src={API_URL +'/'+ talent.video.path } />
          </video>
        )
      }
    }
  }

  async function closeTalent() {
    let updatedTalent = talent;
    if (text) updatedTalent.text = text;
    if (talent._id) {
      await updateTalentAction(dispatch , updatedTalent )
    } else {
      updatedTalent.company = companyId;
      updatedTalent.lang = lang;
      await createTalentAction(dispatch , updatedTalent )
    }
    getTalentsAction(dispatch, lang)
    setIsEdit(false);
  }

  function renderEdit() {
    return (
      <>
        <div className="btn-close-row">
          <button
            type="button"
            style={{ width: 30 }}
            onClick={() => closeTalent()}
            >
            <img src={save} alt="edit" />
          </button>
          <button
            onClick={() => setIsEdit(false)}
            >
            <img src={close} alt="edit" />
          </button>
        </div>
        <label htmlFor={`title`} >Nom</label>
        <input
          name={`title`}
          style={{ marginBottom: 10 }}
          onChange={(e) => setTalent({...talent, title: e.target.value })}
          defaultValue={talent && talent.title ? talent.title : ""}
          />

        <label htmlFor={`function`} >Fonction</label>
        <input
          name={`function`}
          style={{ marginBottom: 10 }}
          onChange={(e) => setTalent({...talent, function: e.target.value })}
          defaultValue={talent && talent.function ? talent.function : ""}
          />

        <label>paragraphe</label>
        <TextEditor
          value={talent && talent.text}
          onChange={newContent => setText(newContent.replace(/(<p>)/gi, "").replaceAll("<br>", "").replaceAll("</p>", "</br></br>"))}
        />

        <label>Photo du talent <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 800 x 1000 px</span></label>
        <div className="btn-container">
          
          <button
            type="button"
            className={`btn grey`}
            style={{ marginRight : 20 }}
            onClick={() => openSelectPicture(index , "talentPicture")}
            >
            Parcourir
          </button>

          {talent && talent.picture &&
            <button
              type="button" 
              className={`btn grey bold`}
              onClick={() => setRemoveAction({ function: () => setTalent({...talent, picture: null })}) }
              >
              Supprimer la photo
            </button>
          }
        </div>

        <div>{renderTalentImg()}</div>

        <label>Video du talent <br/><span className="note">minimum 1920 x 1080 px</span></label>
        <div className="btn-container">
          
          <button
            type="button"
            className={`btn grey bold`}
            style={{ marginRight : 20 }}
            onClick={() => openSelectTalentVideo(index)}
            >
            Parcourir
          </button>

          {talent && talent.video &&
            <button
              type="button" 
              className={`btn grey bold`}
              onClick={() => setRemoveAction({ function: () => setTalent({...talent, video: null }) }) }
              >
              Supprimer
            </button>
          }
        </div>
        <div>{renderTalentVideo()}</div>
      </>
    )
  }

  function renderRow() {
    return (
      <>
        {talent && talent._id &&
          <>
            <div className="name">
              {talent.picture ? renderTalentImg() :  <div className="circle"></div>}
              <p className="bold">{talent.title} <span className="text-grey"> / {talent.function}</span></p>
            </div>
            <div className="actions">

              <button
                type="button"
                onClick={() => setIsEdit(true)}
                >
                <img src={edit} alt="edit" />
              </button>

              <button
                type="button"
                onClick={() => setRemoveAction({ function: () => deleteTalentAction(dispatch, talent._id)} )}>
                <img src={remove} alt="remove"/>
              </button>

            </div>
          </>
        }
      </>
    )
  }

  return (
    <div id={`talent${index}`} className={`talent ${!isEdit? "list-item row" : "edit" }`}>
      {isEdit ? renderEdit() : renderRow()}
    </div>
  )
}
